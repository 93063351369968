<template>
	<v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
		<v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
			<v-icon v-if="value"> mdi-view-quilt </v-icon>

			<v-icon v-else> mdi-dots-vertical </v-icon>
		</v-btn>

		<v-toolbar-title class="hidden-sm-and-down font-weight-light">
			<h1>{{ titleApp }}</h1>
			</v-toolbar-title>

		<v-spacer />
		<!--
    <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
-->
		<div class="mx-3" />
		<!--
    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>
-->

		<v-menu
			bottom
			left
			offset-y
			origin="top right"
			transition="scale-transition"
		>
			<template v-slot:activator="{ attrs, on }">
				<v-btn
					class="ml-2"
					min-width="0"
					text
					v-bind="attrs"
					v-on="on"
					@click="$goBack()"
				>
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
			</template>
		</v-menu>
		<dashboard-core-notification />
		<!--<dashboard-core-settings :menuUser="false" />-->

		<v-menu
			bottom
			left
			offset-y
			origin="top right"
			transition="scale-transition"
		>
			<template v-slot:activator="{ attrs, on }">
				<v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
					<v-icon>mdi-cog</v-icon>
				</v-btn>
			</template>
			<v-list :tile="false" nav dense>
				<div>
					<dashboard-core-settings :menuUser="true" />
					<v-list-item @click="$router.push({ path: '/perfil' })"
						><v-icon>mdi-account</v-icon
						><span class="mt-1 ml-1">Mi Perfil</span></v-list-item
					>

					<v-list-item href="#" @click="logout()"
						><v-icon>mdi-logout</v-icon
						><span class="mt-1 ml-1">Salir</span></v-list-item
					>
				</div>
			</v-list>
		</v-menu>
	</v-app-bar>
</template>

<script>
	import axios from "axios";
	// Components
	import { VHover, VListItem } from "vuetify/lib";

	// Utilities
	import { mapState, mapMutations } from "vuex";
	import { mapActions, mapGetters } from "vuex";

	export default {
		name: "DashboardCoreAppBar",

		components: {
			AppBarItem: {
				render(h) {
					return h(VHover, {
						scopedSlots: {
							default: ({ hover }) => {
								return h(
									VListItem,
									{
										attrs: this.$attrs,
										class: {
											"black--text": !hover,
											"white--text secondary elevation-12": hover,
										},
										props: {
											activeClass: "",
											dark: hover,
											link: true,
											...this.$attrs,
										},
									},
									this.$slots.default
								);
							},
						},
					});
				},
			},

			DashboardCoreNotification: () => import("./Notifications"),
			DashboardCoreSettings: () => import("./Settings"),
		},

		props: {
			value: {
				type: Boolean,
				default: false,
			},
		},

		data: () => ({
			notifications: [],
			/*items: [{ text: "Profile", icon: "mdi-clock", link: "/pages/user" }],*/
			intervalId: 0,
			titleAppData: "",
			routeNotificaciones: "notificacionesChangeStatus",
		}),

		computed: {
			...mapState(["drawer"]),
			...mapGetters({
				isLogged: "authenticated",
			}),
			...mapState("aduana", ["sourceVentaElementosAduanaSt"]),
			titleApp: {
				get() {
					return this.titleAppData || this.$route.meta.titleApp || "";
				},
				set(value) {
					this.titleAppData = value;
				},
			},
		},
		created() {
			let path = this.$route.path;
			if (path[0] == "/") {
				path = path.slice(1);
			}
			let titulo = "";

			let menuTitulo = this.$router.options.routes[0].children.filter(
				(o) => o.path === path
			);

			//console.log(this.$router.options.routes[0].children);
			//console.log(this.$router.options.routes[0].children);
			this.titleApp = menuTitulo[0] ? menuTitulo[0].meta.titleApp + titulo : "";
			/*if(this.isLogged){
																																																																																																																																																																																																																																	      this.getAlertas();
																																																																																																																																																																																																																																	    }*/
		},
		mounted: function () {
			this.intervalId = window.setInterval(() => {
				//console.log('esta logueado: '+this.isLogged);
				if (this.isLogged == true) {
					/*var today = new Date();
																																																																																																																																																																																																																																	          var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
																																																																																																																																																																																																																																	          var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
																																																																																																																																																																																																																																	          var dateTime = date+' '+time;*/
					//console.log('consultando alertas: '+dateTime);
					//this.getAlertas();
				} else {
					window.clearInterval(this.intervalId);
				}
			}, 600000); //10 minutos
			//}, 300000) //5 minutos
			//}, 10000)
		},
		methods: {
			...mapActions({
				signOut: "logout",
			}),
			async delay(ms) {
				return new Promise((resolve) => setTimeout(resolve, ms));
			},
			async logout() {
				var logoutOptions = { redirectUri: "" };
				this.signOut();
				sessionStorage.clear();
				Swal.alertGetInfo("Cerrando Sesion");
				await this.delay(1000);
				this.$keycloak
					.logout(logoutOptions)
					.then((success) => {
						Swal.close();
						console.log("--> log: logout success ", success);
					})
					.catch((error) => {
						Swal.close();
						console.log("--> log: logout error ", error);
					});
				//store.commit("logout");
			},
			async showNotificacion(item, estado) {
				this.changeNotificationStatus(item, estado);
				this.$router.push({ path: item.link });
			},
			async changeNotificationStatus(item, estado) {
				let formData = {
					notificacion_id: item.id,
					usuario_id: this.$store.state.user.id,
					leida: estado,
				};
				this.$axiosApi.post(this.routeNotificaciones, formData).then(
					(response) => {
						console.log(response);
						if (response.status == 200) {
							this.$getNotificaciones();
						} else {
							console.log(response);
						}
					},
					(error) => {
						console.log(error);
					}
				);
			},

			...mapMutations({
				setDrawer: "SET_DRAWER",
			}),
			submit: function () {
				this.$refs.form.submit();
			},
			capitalize(s) {
				if (typeof s !== "string") return "";
				return s.charAt(0).toUpperCase() + s.slice(1);
			},
		},
	};
</script>
